import React, {useContext, useEffect, useState} from 'react';
import { useTour } from '@reactour/tour'
import {api, AppDataContext, getUserMetadataAPI, getUserName} from "@iblai/ibl-web-react-common";
import { useNavigate } from 'react-router-dom';
import { getSkillsToursSteps } from '../helpers';


const useTourTooltips = () => {

  const navigate = useNavigate()
  const { isOpen, beforeClose,
    currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()

  const {appData} = useContext(AppDataContext)

  const [tourTooltipStarted, setTourTooltipStarted] = useState(false)
  const [userHasPreviouslyLoadedTourTooltips, setUserHasPreviouslyLoadedTourTooltips] = useState(true)

  const tourSteps = getSkillsToursSteps(setSteps, navigate)

  useEffect(() => {
    if(typeof appData.userMetaData?.public_metadata?.tour_tooltips?.loaded_on_skills_spa === "boolean"){
      setUserHasPreviouslyLoadedTourTooltips(appData.userMetaData?.public_metadata?.tour_tooltips?.loaded_on_skills_spa)
    }else{
      getUserMetadataAPI(getUserName(), (metadata)=>{
        const hasBeenPreviouslyLoaded = metadata?.public_metadata?.tour_tooltips?.loaded_on_skills_spa===true
        setUserHasPreviouslyLoadedTourTooltips(hasBeenPreviouslyLoaded)
      })
    }
  }, [appData?.userMetaData?.public_metadata]);

  const handleUpdateTourTooltipsStatusOnUserMetaData = () => {
    const updatedPublicMetaData = {
      ...appData?.userMetaData?.public_metadata,
      tour_tooltips:{
        ...appData?.userMetaData?.public_metadata?.tour_tooltips,
        loaded_on_skills_spa:true
      }
    }
    const { bio, about, is_profile_public, social_links, profile_image, language } = appData?.userMetaData
    const updatedMetaData = {
      bio,
      about,
      is_profile_public,
      language,
      social_links,
    };
    const metaDataToSubmit = {
      public_metadata: {
        ...updatedPublicMetaData,
        ...updatedMetaData,
        profile_image,
      },
      ...updatedMetaData,
      username: getUserName()
    };
    api.ibledxusers.postUsersManageMetadata(
      JSON.stringify(metaDataToSubmit),
      (metadata)=>{
        setUserHasPreviouslyLoadedTourTooltips(true)
      })
  }

  const handleStartTour = () => {
    setSteps(tourSteps)
    setIsOpen(true)
    setTourTooltipStarted(true)
  }

  useEffect(() => {
    if(!isOpen && tourTooltipStarted){
      handleUpdateTourTooltipsStatusOnUserMetaData()
    }
  }, [tourTooltipStarted, isOpen]);

  useEffect(()=>{
    if(!userHasPreviouslyLoadedTourTooltips){
      handleStartTour()
    }
  }, [userHasPreviouslyLoadedTourTooltips])

  useEffect(() => {
    if(currentStep===0){
      return
    }
    if(currentStep===tourSteps.length-1){
      setSteps([])
      return
    }
    tourSteps[currentStep-1]?.customAction()
    setTimeout(()=>{
      setCurrentStep(currentStep)
      setSteps(tourSteps)
    }, 1500)
  }, [currentStep]);

  return {
    handleStartTour, tourSteps, isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps
  }
};

export default useTourTooltips;